import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Box } from '@mui/system';
import ClearIcon from '@mui/icons-material/Clear';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import React from 'react';
import { createReceipt } from '../fetchers/receipt.fetch';
import useMutation from '../services/httpClient/useMutation';

const initialStates = (company) => ({
  paper: {
    company,
    date: DateTime.now().startOf('day'),
    sn: '',
    amount: 0,
    name: '',
    tax: '',
  },
  electronic: {
    print: true,
    company,
    amount: 0,
    customer: {},
    tax: '',
    carrier: '',
    donation: '',
  },
  items: [
    {
      id: 1,
      name: '',
      quantity: 1,
      unit: '',
      price: 0,
      remark: '',
    },
  ],
  item: {
    name: '',
    quantity: 1,
    unit: '',
    price: 0,
    remark: '',
  },
});
const buttons = [
  {
    name: '預收管理費',
    quantity: 1,
    unit: '式',
    price: 20000,
    remark: '',
  },
  {
    name: '管理費',
    quantity: 1,
    unit: '式',
    price: 20000,
    remark: '',
  },
  {
    name: '塔位',
    quantity: 1,
    unit: '座',
    price: 0,
    remark: '',
  },
  {
    name: '靈位',
    quantity: 1,
    unit: '座',
    price: 0,
    remark: '',
  },
  {
    name: '骨罈',
    quantity: 1,
    unit: '座',
    price: 0,
    remark: '',
  },
  {
    name: '光明燈',
    quantity: 1,
    unit: '座',
    price: 0,
    remark: '',
  },
  {
    name: '代辦費',
    quantity: 1,
    unit: '式',
    price: 0,
    remark: '',
  },
  {
    name: '代辦費-佛事',
    quantity: 1,
    unit: '式',
    price: 0,
    remark: '',
  },
  {
    name: '景觀工程材料費',
    quantity: 1,
    unit: '式',
    price: 0,
    remark: '',
  },
  {
    name: '面板工程費',
    quantity: 1,
    unit: '式',
    price: 2000,
    remark: '',
  },
  {
    name: '佣金',
    quantity: 1,
    unit: '式',
    price: 0,
    remark: '',
  },
  {
    name: '暫厝費',
    quantity: 1,
    unit: '式',
    price: 1500,
    remark: '',
  },
  {
    name: '場租費',
    quantity: 1,
    unit: '小時',
    price: 1500,
    remark: '',
  },
  {
    name: '運輸費',
    quantity: 1,
    unit: '趟',
    price: 100,
    remark: '',
  },
  {
    name: '超時費',
    quantity: 1,
    unit: '式',
    price: 0,
    remark: '',
  },
  {
    name: '運輸費-法會',
    quantity: 1,
    unit: '趟',
    price: 100,
    remark: '',
  },
  {
    name: '祭祀用品',
    quantity: 1,
    unit: '式',
    price: 2000,
    remark: '',
  },
  {
    name: '服務費-大帳篷',
    quantity: 1,
    unit: '式',
    price: 1500,
    remark: '',
  },
  {
    name: '服務費-小帳篷',
    quantity: 1,
    unit: '式',
    price: 1000,
    remark: '',
  },
  {
    name: '公共設施使用暨環境維護費',
    quantity: 1,
    unit: '天',
    price: 5000,
    remark: '',
  },
];

function AddReceiptDialog({
  mode = 'paper',
  tag,
  onCancel,
  onCreated,
  amount = 50,
  company,
}) {
  const [receipt, setReceipt] = React.useState();
  const [collapse, setCollapse] = React.useState(true);
  const [items, setItems] = React.useState(
    initialStates(company ?? '福').items
  );
  const createReceiptRequest = useMutation(createReceipt, {
    onSuccess: (data) => onCreated(data),
  });

  const columns = [
    {
      field: 'name',
      headerName: '品項',
      flex: 1,
      editable: true,
    },
    {
      field: 'quantity',
      headerName: '數量',
      type: 'number',
      flex: 1,
      editable: true,
    },
    {
      field: 'unit',
      headerName: '單位',
      flex: 1,
      editable: true,
    },
    {
      field: 'price',
      headerName: '金額',
      type: 'number',
      flex: 1,
      editable: true,
    },
    {
      field: 'remark',
      headerName: '備註',
      flex: 1,
      editable: true,
    },
    {
      field: 'id',
      headerName: '',
      flex: 0.2,
      renderCell: (params) => (
        <IconButton onClick={() => handleDelete(params.value)}>
          <ClearIcon />
        </IconButton>
      ),
    },
  ];

  const handleDelete = React.useCallback((id) => {
    setTimeout(() => {
      setItems((state) => state.filter((row) => row.id !== id));
    });
  }, []);
  const addRow = React.useCallback((item) => {
    if (!item) item = initialStates(company).item;

    return setItems((items) => {
      let updated;
      if (items.length === 1 && items[0].name === '' && items[0].price === 0) {
        updated = [
          { ...items[0], name: item.name, price: item.price, unit: item.unit },
        ];
      } else {
        updated = [
          ...items,
          {
            ...item,
            id: items.reduce(
              (max, item) => (item.id > max ? item.id : max) + 1,
              0
            ),
          },
        ];
      }
      return updated;
    });
  }, []);

  React.useEffect(() => {
    setReceipt(initialStates(company ?? '福')[mode]);
    setItems(initialStates(company ?? '福').items);
  }, [mode]);

  if (!receipt) return <p>Loading...</p>;

  const total = items.reduce(
    (sum, item) => sum + item.quantity * item.price,
    0
  );
  function handleField(field) {
    const handleFieldChange = (event) => {
      if (field === 'date') {
        return setReceipt({ ...receipt, [field]: event.startOf('day') });
      }
      if (
        (field === 'carrier' || field === 'donation') &&
        event.target.value.length > 0
      ) {
        return setReceipt({
          ...receipt,
          [field]: event.target.value,
          print: false,
        });
      }
      if (field === 'tax' && !/^\d{0,8}$/.test(event.target.value)) return;
      if (field === 'sn') {
        if (!/^[A-Za-z]{0,2}$|^[A-Za-z]{2}\d{0,8}$/.test(event.target.value))
          return;
        event.target.value = event.target.value.toUpperCase();
      }
      setReceipt({ ...receipt, [field]: event.target.value });
    };
    return {
      value: receipt[field],
      onChange: handleFieldChange,
    };
  }
  function handleEditRowsModelChange(model) {
    for (let id of Object.keys(model)) {
      const updated = items.map((item) => {
        if (id === item.id.toString()) {
          return {
            ...item,
            name: model[id].name.value,
            quantity: model[id].quantity.value,
            unit: model[id].unit.value,
            price: model[id].price.value,
            remark: model[id].remark.value,
          };
        }
        return item;
      });
      setItems(updated);
    }
  }
  function handleSave() {
    if (createReceiptRequest.isLoading) return;
    createReceiptRequest.mutate({ receipt: { ...receipt, items }, tag });
  }
  const paperInputs = () => (
    <>
      <Grid item xs={12} sm={6}>
        <FormControl size="small" fullWidth>
          <InputLabel>公司</InputLabel>
          <Select
            label="公司"
            {...handleField('company')}
            inputProps={{ readOnly: !!company }}>
            <MenuItem value="利">頂利事業</MenuItem>
            <MenuItem value="福">頂福事業</MenuItem>
            <MenuItem value="茂">辰茂事業</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DatePicker
          label="發票日期"
          inputFormat="yyyy-MM-dd"
          {...handleField('date')}
          renderInput={(params) => (
            <TextField size="small" fullWidth {...params} />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          size="small"
          label="發票號碼"
          {...handleField('sn')}
          autoFocus
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          size="small"
          label="金額"
          value={total}
          inputProps={{ readOnly: true }}
          type="number"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          size="small"
          label="買受人"
          {...handleField('name')}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          size="small"
          label="統一編號"
          {...handleField('tax')}
          fullWidth
        />
      </Grid>
    </>
  );
  const electronicInputs = () => {
    return (
      <>
        <Grid item xs={12} sx={{ textAlign: 'right' }}>
          <FormControlLabel
            control={
              <Checkbox
                sx={{ fontSize: 28, marginLeft: 2 }}
                checked={receipt.print}
                onChange={(event) =>
                  setReceipt({ ...receipt, print: event.target.checked })
                }
                disabled={
                  receipt.carrier.length !== 0 || receipt.donation.length !== 0
                }
              />
            }
            label="列印"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl size="small" readOnly fullWidth>
            <InputLabel>公司</InputLabel>
            <Select
              label="公司"
              {...handleField('company')}
              inputProps={{ readOnly: company }}>
              <MenuItem value="福">頂福事業</MenuItem>
              <MenuItem value="利">頂利事業</MenuItem>
              <MenuItem value="茂">辰茂事業</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            size="small"
            label="金額"
            value={total}
            inputProps={{ readOnly: true }}
            type="number"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField variant="outlined" size="small" label="買受人" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            size="small"
            label="統一編號"
            {...handleField('tax')}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            size="small"
            label="載具"
            {...handleField('carrier')}
            inputProps={{
              readOnly: receipt.donation.length !== 0,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            size="small"
            label="捐贈碼"
            {...handleField('donation')}
            inputProps={{
              readOnly: receipt.carrier.length !== 0,
            }}
            fullWidth
          />
        </Grid>
      </>
    );
  };

  return (
    <Dialog open={true} maxWidth="md" fullWidth>
      <DialogTitle>新增發票</DialogTitle>
      <Box sx={{ padding: '0 20px 20px 20px' }}>
        <Grid container spacing={2}>
          {mode === 'paper' ? paperInputs() : electronicInputs()}
          <Grid item xs={12}>
            <Box
              sx={{
                '.buttons': {
                  display: collapse ? 'none' : 'inline-block',
                },
              }}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <div className="buttons">
                    {buttons.map((item) => (
                      <Button
                        key={item.name}
                        variant="text"
                        onClick={() => addRow(item)}>
                        {item.name}
                      </Button>
                    ))}
                  </div>
                  <IconButton onClick={() => setCollapse(!collapse)}>
                    {collapse ? <ArrowRightIcon /> : <ArrowLeftIcon />}
                  </IconButton>
                </Grid>
                {collapse && (
                  <Grid item>
                    <Button variant="text" onClick={() => addRow()}>
                      新增品項
                    </Button>
                  </Grid>
                )}
              </Grid>
              <DataGrid
                style={{ minHeight: 280 }}
                columns={columns}
                rows={items}
                editMode="row"
                onEditRowsModelChange={handleEditRowsModelChange}
                hideFooter
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ pt: 2, textAlign: 'right' }}>
              <Button variant="contained" color="secondary" onClick={onCancel}>
                取消
              </Button>
              {total >= amount &&
                (mode === 'electronic' || receipt.sn) &&
                items.length > 0 &&
                items[0].name &&
                items[0].unit && (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ ml: '15px' }}
                    onClick={handleSave}
                    disabled={createReceiptRequest.isLoading}>
                    儲存
                  </Button>
                )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

export default AddReceiptDialog;
