import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Link, useSearchParams } from 'react-router-dom';
import SpeedDial, { createAction } from '../../services/speedDial';
import { grey, red } from '@mui/material/colors';
import { labels, tagTypes } from '../../utils/tagTypes.const';

import AddReceiptDialog from '../../components/AddReceiptDialog';
import { Box } from '@mui/system';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import { ListPage } from '../../components/ListPage';
import React from 'react';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { ReceiptInfoDialog } from './ReceiptInfoDialog';
import Spinner from '../../components/Spinner';
import companies from '../../utils/companies.const';
import crumbs from '../../services/crumbs/crumbs.const';
import getSearchParams from '../../utils/getSearchParams';
import { handleParams } from '../../utils/handleParams';
import { queryReceipts } from '../../fetchers/receipt.fetch';
import speedDialIcons from '../../services/speedDial/speedDialIcons';
import useCrumbs from '../../services/crumbs/useCrumbs';
import useQuery from '../../services/httpClient/useQuery';

const columns = [
  {
    field: 'date',
    headerName: '日期',
    valueGetter: (params) => params.value?.toDate(),
    sortComparator: (v1, v2) => {
      return Date.parse(v1) - Date.parse(v2);
    },
    flex: 0.8,
  },
  {
    field: 'company',
    headerName: '公司',
    headerAlign: 'center',
    align: 'center',
    flex: 0.5,
    renderCell: (params) => (
      <span
        style={{
          color: params.row.invalidated
            ? '#707070'
            : companies[params.row.company].color,
          marginRight: 5,
        }}>
        {params.row.company}
      </span>
    ),
  },
  {
    field: 'sn',
    headerName: '發票號碼',
    flex: 1,
  },
  {
    field: 'items',
    headerName: '品項',
    valueGetter: (params) => params.row.items.map((i) => i.name).join('、'),
    flex: 1,
  },
  {
    field: 'amount',
    headerName: '金額',
    valueGetter: (params) => params.value,
    flex: 0.8,
    type: 'number',
  },
  {
    field: 'tag',
    headerName: '標籤',
    valueGetter: (params) => {
      const payment = params.row.payments.find((p) => p.tag);
      if (payment) return payment.tag.label;
      return '';
    },
    renderCell: (params) => {
      const payment = params.row.payments.find((p) => p.tag);
      if (payment) {
        return (
          <Link to={`/${payment.tag.tagType}/${payment.tag._id}`}>
            {payment.tag.tagType === 'Product'
              ? labels[payment.tag.label.substring(0, 1)]
              : tagTypes[payment.tag.tagType]}
            {params.row.payments[0].tag.label}
          </Link>
        );
      }
    },
    flex: 1,
  },
  {
    field: 'status',
    headerName: '狀態',
    flex: 0.8,
    valueGetter: (params) => params.value,
    renderCell: (params) => {
      if (params.value === '正常') return <span>正常</span>;
      if (params.value === '作廢')
        return <span style={{ color: grey[500] }}>作廢</span>;
      return <span style={{ color: red[700] }}>{params.value}</span>;
    },
    sortComparator: (v1, v2) => (v1 > v2 ? 1 : -1),
  },
];

function ReceiptList(props) {
  useCrumbs([crumbs.receiptList]);
  const [params, setParams] = useSearchParams();
  const [keywords, setKeywords] = React.useState(handleParams(params));
  const [query, setQuery] = React.useState(keywords);
  const receiptsQuery = useQuery(
    ['receipts', keywords],
    queryReceipts(keywords),
    {
      placeholder: [],
      staleTime: 30000,
    }
  );
  const [state, setState] = React.useState({
    create: null,
    delete: {
      open: false,
      desc: '',
      check: false,
    },
  });

  if (receiptsQuery.isLoading) return <Spinner />;
  if (receiptsQuery.isError) return <p>{receiptsQuery.error.toString()}</p>;

  function getQueryInputs(startProps, endProps) {
    return (
      <Grid container spacing={2} sx={{ p: '20px' }}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            size="small"
            label="發票號碼"
            value={query.receipt || ''}
            onChange={(event) =>
              setQuery({ ...query, receipt: event.target.value })
            }
            onKeyDown={handleKeyDown}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            {...startProps}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField variant="outlined" size="small" fullWidth {...endProps} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl size="small" fullWidth>
            <InputLabel>公司</InputLabel>
            <Select
              label="公司"
              value={query.company || ''}
              onChange={(event) =>
                setQuery({ ...query, company: event.target.value })
              }>
              <MenuItem value="">全部</MenuItem>
              <MenuItem value="福">頂福事業</MenuItem>
              <MenuItem value="利">頂利事業</MenuItem>
              <MenuItem value="茂">辰茂事業</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            size="small"
            label="客戶資料"
            value={query.customer || ''}
            onChange={(event) =>
              setQuery({ ...query, customer: event.target.value })
            }
            onKeyDown={handleKeyDown}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            size="small"
            label="品項"
            value={query.item || ''}
            onChange={(event) =>
              setQuery({ ...query, item: event.target.value })
            }
            onKeyDown={handleKeyDown}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl size="small" fullWidth>
            <InputLabel>地點/部門</InputLabel>
            <Select
              label="地點/部門"
              value={query.location || ''}
              onChange={(event) =>
                setQuery({ ...query, location: event.target.value })
              }>
              <MenuItem value="">全部</MenuItem>
              <MenuItem value="北">台北</MenuItem>
              <MenuItem value="林">林口</MenuItem>
              <MenuItem value="行">行政部</MenuItem>
              <MenuItem value="管">管理部</MenuItem>
              <MenuItem value="業">業務部</MenuItem>
              <MenuItem value="工">工務部</MenuItem>
              <MenuItem value="財">財務室</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  }
  function handleChange(field) {
    return (event) => {
      setQuery({
        ...query,
        [field]: field === 'range' ? event : event.target.value,
      });
    };
  }
  function handleSearch() {
    const updated = { ...query };
    if (!updated.range) {
      updated.range = [
        DateTime.now().startOf('month').setZone('Asia/Taipei'),
        DateTime.now().endOf('month').setZone('Asia/Taipei'),
      ];
      setQuery(updated);
    }
    setKeywords(updated);
    setParams({
      ...updated,
      range: [updated.range[0].toMillis(), updated.range[1].toMillis()],
    });
  }
  function handleKeyDown(event) {
    event.stopPropagation();
    if (event.key === 'Enter' && event.target.value.length > 0) {
      event.preventDefault();
      handleSearch();
    }
  }

  return (
    <Box>
      <ListPage
        columns={columns}
        rows={receiptsQuery.data}
        onCellDoubleClick={(params) =>
          setState({ ...state, receipt: params.row })
        }
        page={Number(params.get('page'))}
        onPageChange={(page) => setParams({ ...getSearchParams(params), page })}
        pageSize={Number(params.get('page-size'))}
        onPageSizeChange={(size) =>
          setParams({ ...getSearchParams(params), 'page-size': size })
        }>
        <DateRangePicker
          inputFormat="yyyy-MM-dd"
          value={
            query.range || [
              DateTime.now().startOf('month'),
              DateTime.now().endOf('month'),
            ]
          }
          onChange={handleChange('range')}
          renderInput={getQueryInputs}
        />
      </ListPage>
      <ReceiptInfoDialog
        state={state}
        setState={setState}
        onDelete={() => receiptsQuery.refetch()}
      />
      {state.create && (
        <AddReceiptDialog
          mode="electronic"
          onCancel={() => setState({ ...state, create: false })}
          onCreated={(receipt) => {
            setState({ ...state, receipt, create: false });
            receiptsQuery.refetch();
          }}
        />
      )}
      <SpeedDial
        actions={[
          createAction(speedDialIcons.mainWithSearch, null, handleSearch),
          createAction(<ReceiptIcon />, '開立發票', () =>
            setState({ ...state, create: true })
          ),
        ]}
      />
    </Box>
  );
}

export default ReceiptList;
